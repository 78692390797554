import {
  ConfirmPaymentInvoiceFields,
  ConfirmPaymentInvoiceFormData
} from '../../ConfirmPaymentInvoiceForm.types';
import {
  InvoiceAmount,
  InvoiceID,
  InvoicePaymentMethods,
  PayInvoiceCacheKeys
} from '../../../../../invoicesTypes';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { usePayInvoice } from '../../../../../hooks/usePayInvoice';

import { confirmPaymentInvoiceFormSchema } from './useConfirmPaymentInvoiceForm.schema';

const defaultBillingInfoValues: ConfirmPaymentInvoiceFormData = {
  paymentDate: '',
  transactionFee: 0,
  confirmationId: '',
  comment: ''
};

interface ConfirmPaymentInvoiceFormProps {
  invoiceId: InvoiceID;
  invoiceAmount: InvoiceAmount;
  cacheKeys: PayInvoiceCacheKeys;
}

function useConfirmPaymentInvoiceForm({
  invoiceId,
  invoiceAmount,
  cacheKeys
}: ConfirmPaymentInvoiceFormProps) {
  const {
    control,
    errors,
    handleSubmitReactHookForm,
    register,
    resetForm,
    watch
  } = useReactHookForm<ConfirmPaymentInvoiceFormData>({
    defaultValues: defaultBillingInfoValues,
    isModalForm: true,
    schema: confirmPaymentInvoiceFormSchema
  });

  const { payInvoiceLoading, payInvoiceErrorMessage, handlePayInvoice } =
    usePayInvoice({
      cacheKeys
    });

  return {
    validationErrors: {
      transactionFeeValidationError: errors?.transactionFee?.message,
      paymentDateValidationError: errors?.paymentDate?.message,
      confirmationIdValidationError: errors?.confirmationId?.message,
      commentValidationError: errors?.comment?.message
    },
    control,
    payInvoiceLoading,
    payInvoiceErrorMessage,
    resetPayInvoiceForm: resetForm,
    handleSubmitReactHookForm,
    handlePayInvoice: handleSubmitReactHookForm({
      dirtyFieldsOnly: false,
      onSubmit: async (data) =>
        handlePayInvoice({
          invoiceId,
          invoice: {
            paymentDate: data.paymentDate,
            paymentMethod: InvoicePaymentMethods.WIRE,
            transactionFeeExpense: data.transactionFee || 0,
            received: invoiceAmount - (data.transactionFee || 0),
            paid: invoiceAmount,
            confirmationId: data.confirmationId
          }
        })
    }),
    registerFields: {
      registerTransactionFee: register(
        ConfirmPaymentInvoiceFields.TRANSACTION_FEE
      ),
      registerPaymentDate: register(ConfirmPaymentInvoiceFields.PAYMENT_DATE),
      registerConfirmationId: register(
        ConfirmPaymentInvoiceFields.CONFIRMATION_ID
      ),
      registerComment: register(ConfirmPaymentInvoiceFields.COMMENT)
    },
    watchTransactionFee: watch(ConfirmPaymentInvoiceFields.TRANSACTION_FEE)
  };
}

export default useConfirmPaymentInvoiceForm;
