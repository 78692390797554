import { gql } from 'graphql-request';
import {
  InvoiceAccountTransactionCount,
  InvoiceAmount,
  InvoiceAvInvoiceBillingInfoAddress,
  InvoiceAvInvoiceBillingInfoBankDetails,
  InvoiceAvInvoiceBillingInfoCity,
  InvoiceAvInvoiceBillingInfoCompanyName,
  InvoiceAvInvoiceBillingInfoContactEmail,
  InvoiceAvInvoiceBillingInfoContactName,
  InvoiceAvInvoiceBillingInfoContactPhone,
  InvoiceAvInvoiceBillingInfoCountry,
  InvoiceAvInvoiceBillingInfoID,
  InvoiceAvInvoiceBillingInfoName,
  InvoiceAvInvoiceBillingInfoNanoID,
  InvoiceAvInvoiceBillingInfoNotes,
  InvoiceAvInvoiceBillingInfoPaymentMethods,
  InvoiceAvInvoiceBillingInfoState,
  InvoiceAvInvoiceBillingInfoVatNumber,
  InvoiceAvInvoiceBillingInfoZipCode,
  InvoiceBurning,
  InvoiceClientId,
  InvoiceClientStatus,
  InvoiceCreatedAt,
  InvoiceCurrencyRateID,
  InvoiceDisabledBindButton,
  InvoiceDisabledCancelButton,
  InvoiceDisabledPayCardButton,
  InvoiceDisabledPayDepositButton,
  InvoiceDisabledPayWireButton,
  InvoiceDisabledRefundButton,
  InvoiceDisabledSendButton,
  InvoiceDisabledUnbindButton,
  InvoiceDisabledUncollectibleButton,
  InvoiceDisabledVoidButton,
  InvoiceDueDate,
  InvoiceGeneralLedgerCompanyID,
  InvoiceGeneralLedgerCompanyName,
  InvoiceGeneralLedgerCompanyNanoID,
  InvoiceID,
  InvoiceInvoiceBillingInfoAddress,
  InvoiceInvoiceBillingInfoCity,
  InvoiceInvoiceBillingInfoCompanyName,
  InvoiceInvoiceBillingInfoContactEmail,
  InvoiceInvoiceBillingInfoContactPhone,
  InvoiceInvoiceBillingInfoCountry,
  InvoiceInvoiceBillingInfoFirstName,
  InvoiceInvoiceBillingInfoID,
  InvoiceInvoiceBillingInfoLastName,
  InvoiceInvoiceBillingInfoNanoID,
  InvoiceInvoiceBillingInfoState,
  InvoiceInvoiceBillingInfoVatNumber,
  InvoiceInvoiceBillingInfoZipCode,
  InvoiceInvoiceType,
  InvoiceIssueDate,
  InvoiceJournalRecordImportID,
  InvoiceName,
  InvoiceNanoID,
  InvoiceNotes,
  InvoicePaid,
  InvoicePaidAt,
  InvoicePaymentDate,
  InvoicePaymentMethod,
  InvoicePrepayment,
  InvoicePrepaymentAmount,
  InvoiceProcessingAt,
  InvoiceProjectID,
  InvoiceReceived,
  InvoiceReturnReasons,
  InvoiceSelectedCurrency,
  InvoiceSelectedCurrencyRate,
  InvoiceShowBindButton,
  InvoiceShowCancelButton,
  InvoiceShowPayCardButton,
  InvoiceShowPayDepositButton,
  InvoiceShowPayWireButton,
  InvoiceShowRefundButton,
  InvoiceShowSendButton,
  InvoiceShowUnbindButton,
  InvoiceShowUncollectibleButton,
  InvoiceShowVoidButton,
  InvoiceSmartContractID,
  InvoiceSmartContractProjectOwnerFullName,
  InvoiceSmartContractProjectOwnerNanoID,
  InvoiceSmartContractTaskCreatedAt,
  InvoiceSmartContractTaskName,
  InvoiceSmartContractTaskNanoID,
  InvoiceSmartContractTaskOwnerFullName,
  InvoiceSmartContractTaskOwnerNanoID,
  InvoiceSmartContractTaskProjectCreatedAt,
  InvoiceSmartContractTaskProjectName,
  InvoiceSmartContractTaskProjectNanoID,
  InvoiceStatus,
  InvoiceTempTotalConverted,
  InvoiceTerms,
  InvoiceTotal,
  InvoiceTotalConverted,
  InvoiceTransactionFeeExpense,
  InvoiceUUID,
  InvoiceViewSettings
} from '../invoicesTypes';
import {
  TeamAccountReceivables,
  TeamEmail,
  TeamImageFile,
  TeamUUID
} from '../../teams/teamsTypes';
import { ItemTypeName } from '../../itemTypes/itemTypesTypes';
import { ItemCategoryName } from '../../itemCategories/itemCategoriesTypes';
import {
  ItemPrice,
  ItemSmartContractID,
  ItemViewPrice,
  ItemViewPriceCurrency
} from '../../items/itemsTypes';
import { ID, MayBe } from '../../../types';
import {
  InvoiceCustomFieldBody,
  InvoiceCustomFieldID,
  InvoiceCustomFieldLabel,
  InvoiceCustomFieldLocation,
  InvoiceCustomFieldOrder
} from '../../invoiceCustomFields/invoiceCustomFieldsTypes';
import {
  ItemTagID,
  ItemTagName,
  ItemTagPosition
} from '../../itemTags/itemTagsTypes';

export interface FetchInvoiceQueryResponse {
  id: InvoiceID;
  nanoId: InvoiceNanoID;
  uuid: InvoiceUUID;
  status: InvoiceStatus;
  dueDate: InvoiceDueDate;
  issueDate: InvoiceIssueDate;
  amount: InvoiceAmount;
  accountTransactionCount: InvoiceAccountTransactionCount;
  createdAt: InvoiceCreatedAt;
  processingAt: InvoiceProcessingAt;
  invoiceCustomFields: {
    id: InvoiceCustomFieldID;
    label: InvoiceCustomFieldLabel;
    body: InvoiceCustomFieldBody;
    location: InvoiceCustomFieldLocation;
    order: InvoiceCustomFieldOrder;
  }[];
  invoiceType: InvoiceInvoiceType;
  invoiceTotal: InvoiceTotal;
  invoiceTotalConverted: InvoiceTotalConverted;
  invoiceTempTotalConverted: InvoiceTempTotalConverted;
  paymentMethod: InvoicePaymentMethod;
  showPayWireButton: InvoiceShowPayWireButton;
  disabledPayWireButton: InvoiceDisabledPayWireButton;
  showUncollectibleButton: InvoiceShowUncollectibleButton;
  disabledUncollectibleButton: InvoiceDisabledUncollectibleButton;
  showBindButton: InvoiceShowBindButton;
  disabledBindButton: InvoiceDisabledBindButton;
  showUnbindButton: InvoiceShowUnbindButton;
  disabledUnbindButton: InvoiceDisabledUnbindButton;
  showPayCardButton: InvoiceShowPayCardButton;
  disabledPayCardButton: InvoiceDisabledPayCardButton;
  showSendButton: InvoiceShowSendButton;
  disabledSendButton: InvoiceDisabledSendButton;
  showRefundButton: InvoiceShowRefundButton;
  disabledRefundButton: InvoiceDisabledRefundButton;
  showCancelButton: InvoiceShowCancelButton;
  disabledCancelButton: InvoiceDisabledCancelButton;
  showVoidButton: InvoiceShowVoidButton;
  disabledVoidButton: InvoiceDisabledVoidButton;
  showPayDepositButton: InvoiceShowPayDepositButton;
  disabledPayDepositButton: InvoiceDisabledPayDepositButton;
  burning: InvoiceBurning;
  paid: InvoicePaid;
  paidAt: InvoicePaidAt;
  received: InvoiceReceived;
  transactionFeeExpense: InvoiceTransactionFeeExpense;
  clientId: InvoiceClientId;
  clientStatus: InvoiceClientStatus;
  paymentDate: InvoicePaymentDate;
  prepayment: InvoicePrepayment;
  returnReason: InvoiceReturnReasons;
  viewSettings: InvoiceViewSettings;
  terms: InvoiceTerms;
  projectId: InvoiceProjectID;
  name: InvoiceName;
  notes?: InvoiceNotes;
  prepaymentAmount: InvoicePrepaymentAmount;
  journalRecordImportId: InvoiceJournalRecordImportID;
  currencyRateId: InvoiceCurrencyRateID;
  selectedCurrency: InvoiceSelectedCurrency;
  selectedCurrencyRate: InvoiceSelectedCurrencyRate;
  generalLedger: {
    company: {
      id: InvoiceGeneralLedgerCompanyID;
      nanoId: InvoiceGeneralLedgerCompanyNanoID;
      name: InvoiceGeneralLedgerCompanyName;
      accountReceivables: TeamAccountReceivables;
      uuid: TeamUUID;
      email: TeamEmail;
      image: {
        file: TeamImageFile;
      } | null;
    };
  };
  invoiceBillingInfo: {
    id: InvoiceInvoiceBillingInfoID;
    nanoId: InvoiceInvoiceBillingInfoNanoID;
    companyName: InvoiceInvoiceBillingInfoCompanyName;
    firstName: InvoiceInvoiceBillingInfoFirstName;
    lastName: InvoiceInvoiceBillingInfoLastName;
    contactPhone: InvoiceInvoiceBillingInfoContactPhone;
    contactEmail: InvoiceInvoiceBillingInfoContactEmail;
    country: InvoiceInvoiceBillingInfoCountry;
    state: InvoiceInvoiceBillingInfoState;
    city: InvoiceInvoiceBillingInfoCity;
    address: InvoiceInvoiceBillingInfoAddress;
    zipCode: InvoiceInvoiceBillingInfoZipCode;
    vatNumber?: InvoiceInvoiceBillingInfoVatNumber;
  };
  avInvoiceBillingInfo: {
    id: InvoiceAvInvoiceBillingInfoID;
    nanoId: InvoiceAvInvoiceBillingInfoNanoID;
    name: InvoiceAvInvoiceBillingInfoName;
    companyName: InvoiceAvInvoiceBillingInfoCompanyName;
    contactName: InvoiceAvInvoiceBillingInfoContactName;
    contactPhone: InvoiceAvInvoiceBillingInfoContactPhone;
    contactEmail: InvoiceAvInvoiceBillingInfoContactEmail;
    country: InvoiceAvInvoiceBillingInfoCountry;
    state: InvoiceAvInvoiceBillingInfoState;
    city: InvoiceAvInvoiceBillingInfoCity;
    address: InvoiceAvInvoiceBillingInfoAddress;
    zipCode: InvoiceAvInvoiceBillingInfoZipCode;
    paymentMethod: InvoiceAvInvoiceBillingInfoPaymentMethods;
    notes?: InvoiceAvInvoiceBillingInfoNotes;
    bankDetails: InvoiceAvInvoiceBillingInfoBankDetails;
    vatNumber?: InvoiceAvInvoiceBillingInfoVatNumber;
  };
  secondaryInvoice: MayBe<{
    id: InvoiceID;
    nanoId: InvoiceNanoID;
    amount: InvoiceAmount;
    showPayWireButton: InvoiceShowPayWireButton;
    disabledPayWireButton: InvoiceDisabledPayWireButton;
    showPayCardButton: InvoiceShowPayCardButton;
    disabledPayCardButton: InvoiceDisabledPayCardButton;
    showPayDepositButton: InvoiceShowPayDepositButton;
    disabledPayDepositButton: InvoiceDisabledPayDepositButton;
    clientStatus: InvoiceClientStatus;
    status: InvoiceStatus;
    paidAt: InvoicePaidAt;
    paymentDate: InvoicePaymentDate;
    avInvoiceBillingInfo: {
      paymentMethod: InvoiceAvInvoiceBillingInfoPaymentMethods;
    };
  }>;
  childInvoices: {
    id: InvoiceID;
    item: {
      itemTypeName: ItemTypeName;
      itemCategoryName: ItemCategoryName;
      price: ItemPrice;
      viewPrice: ItemViewPrice;
      viewPriceCurrency: ItemViewPriceCurrency;
      smartContractId: ItemSmartContractID;
      itemItemTags: {
        id: ID;
        itemTag: {
          id: ItemTagID;
          name: ItemTagName;
        };
        position: ItemTagPosition;
      }[];
    };
    smartContract: {
      id: InvoiceSmartContractID;
      task: {
        createdAt: InvoiceSmartContractTaskCreatedAt;
        nanoId: InvoiceSmartContractTaskNanoID;
        name: InvoiceSmartContractTaskName;
        owner: {
          nanoId: InvoiceSmartContractTaskOwnerNanoID;
          fullName: InvoiceSmartContractTaskOwnerFullName;
        };
        project: {
          createdAt: InvoiceSmartContractTaskProjectCreatedAt;
          nanoId: InvoiceSmartContractTaskProjectNanoID;
          name: InvoiceSmartContractTaskProjectName;
          owner: {
            nanoId: InvoiceSmartContractProjectOwnerNanoID;
            fullName: InvoiceSmartContractProjectOwnerFullName;
          };
        };
      };
    };
  }[];
  tempItems: {
    itemTypeName: ItemTypeName;
    itemCategoryName: ItemCategoryName;
    price: ItemPrice;
    viewPrice: ItemViewPrice;
    viewPriceCurrency: ItemViewPriceCurrency;
    itemItemTags: {
      id: ID;
      itemTag: {
        id: ItemTagID;
        name: ItemTagName;
      };
      position: ItemTagPosition;
    }[];
    smartContractId: ItemSmartContractID;
    smartContract: {
      id: InvoiceSmartContractID;
      task: {
        createdAt: InvoiceSmartContractTaskCreatedAt;
        nanoId: InvoiceSmartContractTaskNanoID;
        name: InvoiceSmartContractTaskName;
        owner: {
          nanoId: InvoiceSmartContractTaskOwnerNanoID;
          fullName: InvoiceSmartContractTaskOwnerFullName;
        };
        project: {
          createdAt: InvoiceSmartContractTaskProjectCreatedAt;
          nanoId: InvoiceSmartContractTaskProjectNanoID;
          name: InvoiceSmartContractTaskProjectName;
          owner: {
            nanoId: InvoiceSmartContractProjectOwnerNanoID;
            fullName: InvoiceSmartContractProjectOwnerFullName;
          };
        };
      };
    };
  }[];
}

export const FETCH_INVOICE_QUERY = gql`
  query Invoice($uuid: ID!) {
    invoice(uuid: $uuid) {
      amount
      accountTransactionCount
      avInvoiceBillingInfo {
        address
        bankDetails
        city
        companyName
        contactName
        contactEmail
        contactPhone
        country
        id
        name
        nanoId
        notes
        paymentMethod
        state
        vatNumber
        zipCode
      }
      burning
      childInvoices {
        id
        item {
          itemCategoryName
          itemTypeName
          price
          viewPrice
          viewPriceCurrency
          smartContractId
          itemItemTags {
            id
            itemTag {
              id
              name
            }
            position
          }
        }
        smartContract {
          id
          task {
            createdAt
            nanoId
            name
            owner {
              nanoId
              fullName
            }
            project {
              createdAt
              nanoId
              name
              owner {
                nanoId
                fullName
              }
            }
          }
        }
      }
      clientId
      clientStatus
      currencyRateId
      createdAt
      deferredPaymentDays
      description
      disabledPayCardButton
      disabledPayDepositButton
      disabledPayWireButton
      disabledUncollectibleButton
      disabledBindButton
      disabledUnbindButton
      disabledSendButton
      disabledRefundButton
      disabledCancelButton
      disabledVoidButton
      dueDate
      generalLedger {
        company {
          id
          nanoId
          name
          accountReceivables
          uuid
          email
          image {
            file
          }
        }
      }
      id
      invoiceBillingInfo {
        address
        city
        companyName
        contactEmail
        contactPhone
        country
        firstName
        id
        lastName
        nanoId
        state
        vatNumber
        zipCode
      }
      invoiceCustomFields {
        id
        label
        body
        location
        order
      }
      invoiceTotal
      invoiceTotalConverted
      invoiceTempTotalConverted
      invoiceType
      issueDate
      journalRecordImportId
      name
      nanoId
      notes
      paid
      paidAt
      paymentDate
      paymentMethod
      prepayment
      prepaymentAmount
      processingAt
      projectId
      received
      returnReason
      selectedCurrency
      selectedCurrencyRate
      secondaryInvoice {
        amount
        avInvoiceBillingInfo {
          paymentMethod
        }
        clientStatus
        disabledPayCardButton
        disabledPayDepositButton
        disabledPayWireButton
        id
        invoiceType
        nanoId
        paid
        paidAt
        paymentDate
        showPayCardButton
        showPayDepositButton
        showPayWireButton
        status
      }
      showPayDepositButton
      showPayWireButton
      showUncollectibleButton
      showBindButton
      showUnbindButton
      showPayCardButton
      showSendButton
      showRefundButton
      showCancelButton
      showVoidButton
      status
      tempItems {
        itemTypeName
        itemCategoryName
        itemItemTags {
          id
          itemTag {
            id
            name
          }
          position
        }
        price
        viewPrice
        viewPriceCurrency
        smartContractId
        smartContract {
          id
          task {
            createdAt
            nanoId
            name
            owner {
              nanoId
              fullName
            }
            project {
              createdAt
              nanoId
              name
              owner {
                nanoId
                fullName
              }
            }
          }
        }
      }
      terms
      transactionFeeExpense
      viewSettings
      uuid
    }
  }
`;
